import * as React from 'react'

import { Stack } from '@mui/material'

import { AmountStep } from './amount_step'
import { AuthCodeStep } from './auth_code_step'
import { BankAccountStep } from './bank_account_step'
import { ConfirmationStep } from './confirmation_step'

import type { BankAccount, MarketAsset, SaleQuote } from '../../queries'

export const WithdrawBankForm = () => {
  const [step, setStep] = React.useState(1)
  const [cryptoAmount, setCryptoAmount] = React.useState<number>(0)
  const [bankAccount, setBankAccount] = React.useState<BankAccount | null>(null)
  const [marketAsset, setMarketAsset] = React.useState<MarketAsset | null>(null)
  const [saleQuote, setSaleQuote] = React.useState<SaleQuote | null>(null)

  const handleBack = () => {
    setStep(Math.max(step - 1, 1))
  }

  const handleNext = () => {
    setStep((step) => step + 1)
  }

  return (
    <Stack
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
      spacing={1}
      pb={1}
    >
      {(() => {
        switch (step) {
        case 1: return (
          <AmountStep
            cryptoAmount={cryptoAmount}
            marketAsset={marketAsset}
            handleNext={(marketAsset: MarketAsset, cryptoAmount: number) => {
              setMarketAsset(marketAsset)
              setCryptoAmount(cryptoAmount)
              handleNext()
            }}
          />
        )
        case 2: return (
          <BankAccountStep
            bankAccount={bankAccount}
            handleBack={handleBack}
            handleNext={(bankAccount: BankAccount) => {
              setBankAccount(bankAccount)
              handleNext()
            }}
          />
        )
        case 3: return (
          <AuthCodeStep
            cryptoAmount={cryptoAmount}
            bankAccount={bankAccount}
            marketAsset={marketAsset}
            handleBack={handleBack}
            handleNext={(saleQuote: SaleQuote) => {
              setSaleQuote(saleQuote)
              handleNext()
            }}
          />
        )
        case 4: return (
          <ConfirmationStep
            cryptoAmount={cryptoAmount}
            bankAccount={bankAccount}
            marketAsset={marketAsset}
            saleQuote={saleQuote}
          />
        )
        }
      })()}
    </Stack>
  )
}
