import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
} from '../../components'
import {
  BankAccountFields,
  bankAccountInitialValues as initialValues,
  bankAccountValidationSchema as validationSchema,
} from '../../forms'
import {
  BANK_ACCOUNTS_QUERY,
  CREATE_BANK_ACCOUNT_MUTATION,
} from '../../queries'
import { setFormError, translateError } from '../../services'

import type { BankAccountFormValues as FormValues } from '../../forms'
import type { CreateBankAccountData, CreateBankAccountVars } from '../../queries'
import type { FormikProps } from 'formik'

type CreateBankAccountFormProps = FormikProps<FormValues> & {
  closeDialog: () => void
}

const CreateBankAccountForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  closeDialog,
}: CreateBankAccountFormProps) => (
  <Form>
    <BankAccountFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          {isSubmitting ? 'Agregando...' : 'Agregar'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

export const BankAccountForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [createBankAccount] =
    useMutation<CreateBankAccountData, CreateBankAccountVars>(CREATE_BANK_ACCOUNT_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [BANK_ACCOUNTS_QUERY],
    })

  const handleSubmit = async (values: FormValues) => {
    setFormError(formRef, null)
    const response = await createBankAccount({ variables: values })

    if (response.data?.createBankAccount) {
      closeDialog()
    } else {
      setFormError(formRef, translateError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Button
        fullWidth
        onClick={openDialog}
        startIcon={<Add />}
        variant='outlined'
        size='large'
      >
        Agregar cuenta bancaria
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='sm'
        title='Agregar cuenta bancaria'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <CreateBankAccountForm
              closeDialog={closeDialog}
              {...props}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
