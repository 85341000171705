import * as React from 'react'

import { useQuery } from '@apollo/client'
import { BlurOn, Error } from '@mui/icons-material'
import { List, ListItem, ListItemText, Paper, Skeleton, Typography } from '@mui/material'

import { Currency, Loading } from '../../components'
import { RAMP_CONFIG_QUERY } from '../../queries'

import type {
  PurchaseQuote,
  RampConfigData,
  RampConfigVars,
} from '../../queries'

type QuotePreviewProps = {
  fiatAmount: number
  purchaseQuote?: PurchaseQuote
  errorMsg?: React.ReactNode
}

export const QuotePreview = ({
  fiatAmount,
  purchaseQuote,
  errorMsg,
}: QuotePreviewProps) => {
  const { loading, data } =
    useQuery<RampConfigData, RampConfigVars>(RAMP_CONFIG_QUERY)

  return loading ? (
    <Loading />
  ) : (
    <List disablePadding>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Utilizarás' />
        <ListItemText
          primary={(
            <Currency
              currency={data?.rampConfig?.userFiatCurrency || ''}
              value={fiatAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Obtendrás' />
        <ListItemText
          primary={(typeof purchaseQuote === 'undefined') ? (
            errorMsg ? '--' : <Skeleton />
          ) : (
            <Currency
              currency='USD'
              value={purchaseQuote.cryptoAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary='Precio dólar' />
        <ListItemText
          primary={(typeof purchaseQuote === 'undefined') ? (
            errorMsg ? '--' : <Skeleton />
          ) : (
            <Currency
              currency='USD'
              value={fiatAmount / purchaseQuote.cryptoAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem disableGutters>
        <Paper
          variant='outlined'
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'action.hover',
            gap: 2,
            p: 2,
          }}
        >
          {(typeof errorMsg === 'undefined') ? (
            (typeof purchaseQuote === 'undefined') ? (
              'Obteniendo precio...'
            ) : (
              <React.Fragment>
                <BlurOn />
                Estos valores son aproximados
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <Error />
              <Typography>{errorMsg}</Typography>
            </React.Fragment>
          )}
        </Paper>
      </ListItem>
    </List>
  )
}
