import * as React from 'react'

import {
  Avatar,
  AvatarGroup,
  Chip,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'

import { BlogImage } from '../components'

import type { BlogAuthor, BlogImageData } from '../components'

type BlogPostCardAuthorProps = {
  createdAt: string
  authors: BlogAuthor[]
}

const BlogPostCardAuthor = ({
  createdAt,
  authors,
}: BlogPostCardAuthorProps) => (
  <Stack
    direction='row'
    spacing={2}
  >
    <AvatarGroup max={2}>
      {authors.map((author, index) => (
        <Avatar key={index}>
          <BlogImage
            image={author.avatar}
            alt={author.name}
            objectFit='cover'
          />
        </Avatar>
      ))}
    </AvatarGroup>
    <Stack
      direction='column'
      justifyContent='center'
    >
      <Typography>
        {authors[0].name}
        {authors.length > 1 && ` y ${authors.length - 1} más`}
      </Typography>
      <Typography
        variant='caption'
        color='text.secondary'
      >
        {new Date(createdAt).toLocaleDateString()}
      </Typography>
    </Stack>
  </Stack>
)

type BlogPostCardProps = BlogPostCardAuthorProps & {
  urlPrefix: string
  slug: string
  image: BlogImageData
  title: string
  category: string
}

export const BlogPostCard = ({
  urlPrefix,
  slug,
  image,
  title,
  category,
  createdAt,
  authors,
}: BlogPostCardProps) => (
  <Stack
    height='100%'
    spacing={2}
    justifyContent='space-between'
  >
    <Paper
      elevation={8}
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Link href={`${urlPrefix}/${slug}`}>
        <BlogImage
          image={image}
          alt={title}
        />
      </Link>
      <Chip
        label={category}
        color='primary'
        size='small'
        sx={(theme) => ({
          position: 'absolute',
          bottom: theme.spacing(1),
          left: theme.spacing(1),
          bgcolor: theme.palette.primary.dark,
        })}
      />
    </Paper>
    <Link
      href={`${urlPrefix}/${slug}`}
      color='inherit'
      underline='none'
    >
      <Typography
        variant='h5'
        component='p'
        fontWeight={500}
      >
        {title}
      </Typography>
    </Link>
    <BlogPostCardAuthor
      authors={authors}
      createdAt={createdAt}
    />
  </Stack>
)
