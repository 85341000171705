exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-auth-account-unlock-tsx": () => import("./../../../src/pages/auth/account-unlock.tsx" /* webpackChunkName: "component---src-pages-auth-account-unlock-tsx" */),
  "component---src-pages-auth-confirm-tsx": () => import("./../../../src/pages/auth/confirm.tsx" /* webpackChunkName: "component---src-pages-auth-confirm-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-password-reset-tsx": () => import("./../../../src/pages/auth/password-reset.tsx" /* webpackChunkName: "component---src-pages-auth-password-reset-tsx" */),
  "component---src-pages-auth-password-update-tsx": () => import("./../../../src/pages/auth/password-update.tsx" /* webpackChunkName: "component---src-pages-auth-password-update-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-posts-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/blog/posts/{contentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-posts-contentful-blog-post-slug-tsx" */),
  "component---src-pages-denuncias-tsx": () => import("./../../../src/pages/denuncias.tsx" /* webpackChunkName: "component---src-pages-denuncias-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-renewal-identity-proofs-tsx": () => import("./../../../src/pages/renewal/identity-proofs.tsx" /* webpackChunkName: "component---src-pages-renewal-identity-proofs-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

