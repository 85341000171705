import * as React from 'react'

import { useQuery } from '@apollo/client'
import { AccountBalance } from '@mui/icons-material'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { ACCOUNT_INFORMATION_QUERY } from '../../queries'
import { BANKS } from '../../services'

import type {
  AccountInformationData,
  AccountInformationVars,
  BankAccount,
} from '../../queries'

type BankAccountDisplayProps = {
  bankAccount: BankAccount | null
}

export const BankAccountDisplay = ({
  bankAccount,
}: BankAccountDisplayProps) => {
  const { data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          <AccountBalance />
        </ListItemIcon>
        <ListItemText
          primary={BANKS[bankAccount?.bankCode || ''].label}
          secondary={(
            <React.Fragment>
              N. cuenta: {bankAccount?.number}
              <br />
              N. documento: {data?.accountInformation?.legalDocumentNumber || '...'}
              <br />
              Nombre: {data?.accountInformation?.legalName || '...'}
            </React.Fragment>
          )}
        />
      </ListItem>
    </React.Fragment>
  )
}
