import * as React from 'react'

import {
  Avatar,
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { BlogImage } from '../components'
import { renderRichText } from '../services'

import type { BlogImageData } from '../components'
import type { BlogRichText } from '../services'
import type { PaperProps } from '@mui/material'

export type BlogAuthor = {
  name: string
  avatar: BlogImageData
  bio: BlogRichText
}

type BlogAuthorProfileProps = Pick<PaperProps, 'sx'> & {
  author: BlogAuthor
}

export const BlogAuthorProfile = ({
  author,
  sx = [],
}: BlogAuthorProfileProps) => {
  const theme = useTheme()
  const avatarSize = theme.spacing(12)
  const onSmallScreen = theme.breakpoints.down('sm')
  const isOnSmallScreen = useMediaQuery(onSmallScreen)

  return (
    <Paper
      variant='elevation'
      elevation={12}
      sx={[
        {
          backgroundColor: 'primary.dark',
          color: 'common.white',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          [onSmallScreen]: {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          flexBasis={192}
          spacing={2}
          py={4}
          px={6}
        >
          <Avatar sx={{ width: avatarSize, height: avatarSize }}>
            <BlogImage
              image={author.avatar}
              alt={author.name}
              style={{ width: avatarSize, height: avatarSize }}
              objectFit='cover'
            />
          </Avatar>
          <Typography textAlign='center'>
            {author.name}
          </Typography>
        </Stack>
        {author.bio && (
          <React.Fragment>
            <Divider
              flexItem
              orientation={isOnSmallScreen ? 'horizontal' : 'vertical'}
              sx={(theme) => ({
                borderColor: alpha(
                  theme.palette.common.white,
                  theme.palette.action.activatedOpacity,
                ),
                my: 3,
                [onSmallScreen]: {
                  mx: 3,
                  my: 0,
                },
              })}
            />
            <Box
              px={6}
              py={3}
            >
              {renderRichText(author.bio)}
            </Box>
          </React.Fragment>
        )}
      </Stack>
    </Paper>
  )
}
