import * as React from 'react'

import { Paid } from '@mui/icons-material'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { Currency } from '../../components'
import { humanTimeFormatter } from '../../services'

import type { MarketAsset, SaleQuote } from '../../queries'

type SaleQuoteDisplayProps = {
  saleQuote?: SaleQuote | null
  marketAsset: MarketAsset | null
  cryptoAmount: number
  quoteRemainingTime?: number
}

export const SaleQuoteDisplay = ({
  saleQuote,
  marketAsset,
  cryptoAmount,
  quoteRemainingTime,
}: SaleQuoteDisplayProps) => (
  <ListItem>
    <ListItemIcon>
      <Paid />
    </ListItemIcon>
    <ListItemText
      primary={(
        <Currency
          currency={marketAsset?.symbol || ''}
          value={cryptoAmount}
        />
      )}
      secondary={(
        <React.Fragment>
          <Currency
            currency={saleQuote?.fiatCurrency || ''}
            value={saleQuote?.fiatAmount}
          />
          {(quoteRemainingTime) && (
            <React.Fragment>
              <br />
              Expira en {humanTimeFormatter(quoteRemainingTime)}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    />
  </ListItem>
)
