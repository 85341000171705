import * as React from 'react'

import { Stack } from '@mui/material'

import { AmountStep } from './amount_step'
import { ConfirmationStep } from './confirmation_step'
import { PaymentStep } from './payment_step'
import { AssetBadge } from '../../components'

import type { Payment } from '../../queries'

export const PurchaseForm = () => {
  const [step, setStep] = React.useState(1)
  const [fiatAmount, setFiatAmount] = React.useState<number>(0)
  const [payment, setPayment] = React.useState<Payment>()

  const handleBack = async () => {
    setStep(Math.max(step - 1, 1))
  }

  const handleNext = () => {
    setStep((step) => step + 1)
  }

  return (
    <Stack
      pb={1}
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
    >
      <AssetBadge
        animated
        symbol='USDT'
        height={100}
      />
      {(() => {
        switch (step) {
        case 1: return (
          <AmountStep
            fiatAmount={fiatAmount}
            handleNext={(fiatAmount: number) => {
              setFiatAmount(fiatAmount)
              handleNext()
            }}
          />
        )
        case 2: return (
          <ConfirmationStep
            fiatAmount={fiatAmount}
            handleBack={handleBack}
            handleNext={(payment?: Payment) => {
              setPayment(payment)
              handleNext()
            }}
          />
        )
        case 3: return (
          <PaymentStep
            fiatAmount={fiatAmount}
            payment={payment}
            handleRestart={() => setStep(1)}
          />
        )
        }
      })()}
    </Stack>
  )
}
