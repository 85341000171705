import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Autocomplete,
  Button,
  Collapse,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
  Loading,
  MarketAssetAdornment,
  OptionDisplay,
  OptionTextField,
} from '../../components'
import { CurrencyField } from '../../forms'
import { MARKET_ASSETS_QUERY, USER_OPERATIONS_QUERY } from '../../queries'
import {
  asOperationsArray,
  getTotalAmount,
  isValidAssetAmount,
  withSymbol,
} from '../../services'

import type {
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
  UserOperation,
  UserOperationsData,
  UserOperationsVars,
} from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  cryptoAmount: number
}

const initialValues = (cryptoAmount: number): FormValues => ({
  cryptoAmount,
})

const validationSchema = (totalAmount: number): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    cryptoAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .max(totalAmount, 'Debes ingresar un monto menor a tu balance')
      .test('validFormat', 'Introduce un número con máximo 6 decimales', isValidAssetAmount),
  })

type InnerFormProps = FormikProps<FormValues> & {
  symbol: string
}

const InnerForm = ({
  isSubmitting,
  isValid,
  submitForm,
  symbol,
}: InnerFormProps) => (
  <Form>
    <CurrencyField
      name='cryptoAmount'
      label='Monto a retirar'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <small>{symbol}</small>
          </InputAdornment>
        ),
      }}
      sx={{ mt: 1 }}
      digits={2}
      positive
      fullWidth
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FormWrapperProps = {
  marketAsset: MarketAsset | null
  operations: UserOperation[]
  cryptoAmount: number
  handleSubmit: (marketAsset: MarketAsset, cryptoAmount: number) => void
}

const FormWrapper = ({
  marketAsset,
  operations,
  cryptoAmount,
  handleSubmit,
}: FormWrapperProps) => {
  if (!marketAsset) {
    return null
  }

  const symbol = marketAsset.symbol

  const assetOperations = withSymbol(operations, symbol)
  const totalAmount = Math.floor(1e2 * getTotalAmount(symbol, assetOperations)) / 1e2

  return (
    <React.Fragment>
      <List disablePadding>
        <ListItem disableGutters>
          <ListItemText primary='Disponible para retiro' />
          <ListItemText
            primary={(
              <Currency
                currency={symbol}
                value={totalAmount}
                digits={2}
              />
            )}
            primaryTypographyProps={{ textAlign: 'right' }}
          />
        </ListItem>
      </List>
      <Formik
        initialValues={initialValues(cryptoAmount)}
        validationSchema={validationSchema(totalAmount)}
        onSubmit={(values) => handleSubmit(marketAsset, values.cryptoAmount)}
      >
        {(props) => (
          <InnerForm
            {...props}
            symbol={symbol}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}

type AmountStepProps = {
  marketAsset: MarketAsset | null
  cryptoAmount: number
  handleNext: (marketAsset: MarketAsset, cryptoAmount: number) => void
}

export const AmountStep = ({
  marketAsset,
  cryptoAmount,
  handleNext,
}: AmountStepProps) => {
  const [selectedMarketAsset, setMarketAsset] = React.useState<MarketAsset | null>(marketAsset)

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const operations = asOperationsArray(operationsData?.userOperations)

  return (assetsLoading || operationsLoading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        textAlign='center'
        pb={2}
      >
        Retirar a cuenta bancaria
      </Typography>
      <Autocomplete
        options={marketAssets.filter((asset) => asset.symbol === 'USDT')}
        getOptionLabel={(option) => option.name}
        value={selectedMarketAsset}
        onChange={(_event, newValue) => setMarketAsset(newValue)}
        renderOption={(props, option) => (
          <OptionDisplay {...props}>
            <AssetBadge
              symbol={option.symbol}
              height={20}
            />
            {option.name}
          </OptionDisplay>
        )}
        renderInput={(params) => (
          <OptionTextField
            startAdornment={<MarketAssetAdornment symbol={selectedMarketAsset?.symbol} />}
            name='marketAsset'
            label='Selecciona una criptomoneda'
            params={params}
          />
        )}
        disablePortal
      />
      <Collapse in={!!selectedMarketAsset}>
        <FormWrapper
          marketAsset={selectedMarketAsset}
          operations={operations}
          cryptoAmount={cryptoAmount}
          handleSubmit={handleNext}
        />
      </Collapse>
    </React.Fragment>
  )
}
