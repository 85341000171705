import * as React from 'react'

import { AccountBalance, CheckCircle, History } from '@mui/icons-material'
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

import { BankAccountDisplay } from './bank_account_display'
import { SaleQuoteDisplay } from './sale_quote_display'
import { ButtonContainer, ButtonsContainer } from '../../components'

import type { BankAccount, MarketAsset, SaleQuote } from '../../queries'

type ConfirmationStepProps = {
  cryptoAmount: number
  bankAccount: BankAccount | null
  marketAsset: MarketAsset | null
  saleQuote: SaleQuote | null
}

export const ConfirmationStep = ({
  cryptoAmount,
  bankAccount,
  marketAsset,
  saleQuote,
}: ConfirmationStepProps) => (
  <Stack height='100%'>
    <List
      disablePadding
      aria-label='Retiro completado'
    >
      <ListItem
        divider
        disableGutters
        sx={{ pt: 0, mb: 1 }}
      >
        <ListItemText
          primary='Retiro completado'
          primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
        />
      </ListItem>
      <SaleQuoteDisplay
        saleQuote={saleQuote}
        marketAsset={marketAsset}
        cryptoAmount={cryptoAmount}
      />
      <BankAccountDisplay bankAccount={bankAccount} />
    </List>
    <Stack
      spacing={1}
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={{ mt: 3, mb: 1 }}
    >
      <Typography
        variant='h6'
        component='span'
      >
        Transacción iniciada
      </Typography>
      <CheckCircle color='success' />
    </Stack>
    <Typography textAlign='center'>
      Puedes ver el estado de esta transacción en Historial.
    </Typography>
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../history'
          startIcon={<History />}
          variant='outlined'
        >
          Ir a Historial
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../portfolio'
          startIcon={<AccountBalance />}
          variant='contained'
        >
          Ir a Portafolio
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Stack>
)
