import * as React from 'react'

import {
  Chip,
  Container,
  Grid,
  Paper,
  useMediaQuery,
} from '@mui/material'

import { BlogImage, Subtitle, Title } from '../components'

import type { BlogImageData } from '../components'
import type { Theme } from '@mui/material'

type BlogPostTitleProps = {
  title: string
  lead: string
  category: string
  heroImage: BlogImageData
}

export const BlogPostTitle = ({
  title,
  lead,
  category,
  heroImage,
}: BlogPostTitleProps) => (
  <Container
    maxWidth='lg'
    sx={{ py: 8 }}
  >
    <Grid
      container
      minHeight='min(85vh, 480px)'
      textAlign={useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('md'))
        ? 'left'
        : 'center'
      }
      justifyContent='center'
      alignItems='center'
      spacing={3}
      rowSpacing={4}
    >
      <Grid
        item
        xs={12}
        md={6}
        paddingBottom={3}
      >
        <Title
          mainTitle
          colorful
          paddingBottom={4}
        >
          {title}
        </Title>
        <Subtitle
          mainTitle
          color='text.primary'
          fontWeight={300}
          paddingBottom={5}
        >
          {lead}
        </Subtitle>
        <Chip
          label={category}
          variant='outlined'
          color='primary'
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Paper
          elevation={12}
          sx={{ overflow: 'hidden' }}
        >
          <BlogImage
            image={heroImage}
            alt={title}
            style={{ height: '300px' }}
          />
        </Paper>
      </Grid>
    </Grid>
  </Container>
)
